.unauthorize {
    min-height: 100vh;
    height: fit-content;
    position: relative;
}

.error-container {
    position: absolute;
    height: 30vh;
    width: 40vw;
    background-color: #ffffff86;
    top: 35%;
    left: 30%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-title {
    display: block;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    color: #111;
}

.error-button-back:focus-visible {
    outline:1px solid #591BC5;
}

.error-button-back {
    width: fit-content;
    color: #591BC5;
    font-size: 17px;
    border:1px solid #EFEEF5;
    font-weight: 500;
    background: #EFEEF5;
    border-radius: 20px;
    padding: 15px 30px;
    cursor:pointer;
    transition: .3s;
    display: flex;
    justify-content: center;
}

.error-button-back:hover {
    border-radius: 0;
}