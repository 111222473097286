.about {
    background-color: #fc415a1f;
    background-position: center;
    min-height: 100vh;
    position: relative;
}

.about-container {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-title {
    margin-bottom: 2rem;
}

.about-title-first {
    display: block;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 3.5rem;
    position: relative;
    color: #111;
}

.about-title-first::after {
    content: '';
    position: absolute;
    top: calc(100% + 1.5rem);
    height: 5px;
    width: 3rem;
    background: #591BC5;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
}

.about-title-sec {
    display: block;
    text-align: center;
    color: #555;
    font-size: 1.3rem;
    font-weight: 500;
    max-width: 80rem;
    margin: auto;
    line-height: 1.6;
}

.about-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin: 0 60px;
}

.about-content-me-title, .about-content-exp-title {
    font-weight: 700;
    font-size: 1.8rem;
    margin: auto;
    text-align: center;
    color: #666;
}

.about-content-me p, .about-content-exp li {
    font-size: 1.1rem;
    color: #666;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 1rem;
    text-align: justify;
}

.about-content-exp li {
    margin-bottom: 0;
    line-height: 1.2;
    text-align: left;
}

.about-content-exp h4 {
    font-size: 1.3rem;
    color: #666;
    margin-bottom: 3px;
    margin-top: 19px;
}

.exp-location {
    font-size: 1.2rem;
    color: #666;
    font-style: italic;
}

@media (max-width: 780px) {
    .about-title-first {
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    .about-title-first::after {
        height: 3px;
        width: 2rem;
        top: calc(100% + 1rem);
    }

    .about-title-sec {
        font-size: 1rem;
    }

    .about-content {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .about-content-me-title,
    .about-content-exp-title {
        font-size: 1.3rem;
    }

    .about-content-me p, 
    .about-content-exp li {
        font-size: 1rem;
    }

    .about-content-exp h4 {
        font-size: 1.2rem;
    }

    .exp-location {
        font-size: 1.1rem;
    }
}