@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    min-height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    color: #333;
    overflow-y: scroll;
}
