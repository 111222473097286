header {
    width: 100%;
    height: 83px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.77);
}

.header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    cursor: pointer;
    margin-left: 5em;
}

.container-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: contain;
    background: #FC415A;
    overflow: hidden;
}

.logo-img {
    width: 100%;
    height: auto;
    margin-left: 13%;
    margin-top: 1%;
}

.logo-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    color: #333;
    text-decoration: none;
}

.logo-title:hover {
    transition: color .3s;
    color: #FC415A;
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5em;
    font-family: inherit;
}

@media (max-width: 780px) {
    header {
        justify-content: space-evenly;
    }

    .header-left {
        margin-left: 0;
    }

    .header-right {
        margin-right: 0;
    }
}