.contact {
    min-height: 100vh;
    position: relative;
}

.contact-container {
    position: relative;
    padding: 8em 0;
    max-width: 90rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.contact-title {
    margin-bottom: 2rem;
}

.contact-title-first {
    display: block;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 3.5rem;
    position: relative;
    color: #FFF;
}

.contact-title-first::after {
    content: '';
    position: absolute;
    top: calc(100% + 1.5rem);
    height: 5px;
    width: 3rem;
    background: #FFF;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
}

.contact-content {
    width: 40vw;
    height: fit-content;
    background-color: #ffffff86;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-reseaux {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.social-link,
.social-link:visited {
    font-size: 2rem;
    color: #404040;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.social-link:hover {
    transform: scale(1.3);
}

.fa-linkedin:hover {
    color: #0E76A8;
}

.fa-github:hover {
    color: #000;
}

.contact-content p {
    font-size: 1.1rem;
    color: #404040;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 1rem;
    text-align: justify;
}

.contact-content-title {
    font-weight: 700;
    font-size: 1.8rem;
    margin: auto;
    text-align: center;
    color: #FFF;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form label {
    margin-top: 20px;
    font-size: 1.1rem;
    color: #666;
}

.contact-form input,
.contact-form textarea {
    width: 60%;
    margin-top: 20px;
    border-radius: 20px;
    padding: 10px 30px;
    border: 1px solid #EFEEF5;
    font-size: 1.1rem;
    color: #666;
}

.contact-form button {
  width: fit-content;
  margin-top: 50px;
  color: #591BC5;
  font-size: 17px;
  border: 1px solid #EFEEF5;
  font-weight: 500;
  background: #EFEEF5;
  border-radius: 20px;
  padding: 15px 30px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
}

.contact-form button:hover {
  border-radius: 0;
}

.contact-form input:focus-visible,
.contact-form button:focus-visible {
  outline: 1px solid #591BC5;
}

@media (max-width: 780px) {
    .contact-title-first {
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    .contact-title-first::after {
        height: 3px;
        width: 2rem;
        top: calc(100% + 1rem);
    }

    .contact-content {
        width: 80vw;
    }

    .contact-form input,
    .contact-form textarea {
        width: 80%;
    }
}