.projects {
    background-color: #591BC51f;
    background-position: center;
    min-height: 100vh;
    position: relative;
}

.add-projects-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-title {
    margin-bottom: 2rem;
}

.projects-title-first {
    display: block;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 3.5rem;
    position: relative;
    color: #111;
}

.projects-title-first::after {
    content: '';
    position: absolute;
    top: calc(100% + 1.5rem);
    height: 5px;
    width: 3rem;
    background: #591BC5;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
}

.gallery-projects {
    margin: 40px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-around;
}

.open-modal,
.button-logout {
    width: fit-content;
  color: #591BC5;
  font-size: 17px;
  border:1px solid #591BC5;
  font-weight: 500;
  background: #EFEEF5;
  border-radius: 20px;
  padding: 15px 30px;
  cursor:pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
}

.open-modal:hover,
.button-logout:hover {
    border-radius: 0;
}

.button-logout {
    position: absolute;
    top: 7.5%;
    left: 80%;
    
}