.stack {
    margin-top: 10px;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.stack img {
    width: auto;
    height: 100%;
    transition: transform .3s;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
}

.stack img:hover {
    transform: translateY(-3px);
}

img.mongodb {
    height: 65%;
    background-size: 100%;
}

@media (max-width: 780px) {
    .stack {
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .stack img {
    height: 50%;
    }

    img.mongodb {
        height: 32.5%;
    }
}