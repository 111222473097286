.button {
  width: fit-content;
  margin-top: 50px;
  color: #591BC5;
  font-size: 17px;
  border:1px solid #EFEEF5;
  font-weight: 500;
  background: #EFEEF5;
  border-radius: 20px;
  padding: 15px 30px;
  cursor:pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
}

.invers-button {
  width: fit-content;
  margin-top: 50px;
  color: #EFEEF5;
  font-size: 17px;
  border: 1px solid #591BC5;
  font-weight: 500;
  background: #591BC5;
  border-radius: 20px;
  padding: 15px 30px;
  cursor:pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
}

.button:hover,
.invers-button:hover{
  border-radius: 0;
}