.card-project {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.363);
    padding: 20px;
    border-radius: 20px;
    width: 29%;
}

.card-project img {
    width: 90%;
    height: auto;
    border: 1px solid #591BC5;
    margin: 0 auto;
}

.card-project h3 {
  text-align: center;
}

.button-card,
.button-card-update,
.button-card-delete {
  width: fit-content;
  margin: auto;
  color: #EFEEF5;
  font-size: 17px;
  border: 1px solid #591BC5;
  font-weight: 500;
  background: #591BC5;
  border-radius: 20px;
  padding: 15px 30px;
  cursor:pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.button-card:hover,
.button-card-update:hover,
.button-card-delete:hover{
  border-radius: 0;
}

.button-card:visited,
.button-card-update:visited,
.button-card-delete:visited{
    color: #EFEEF5;
}

.list-button-card {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.card-project-admin {
    width: 45%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.363);
    padding: 20px;
    border-radius: 20px;
    align-items: center;
}

.content-card-project {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.desc-project {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.desc-project h3 {
  text-align: center;
}

.content-card-project img {
    width: 50%;
    height: auto;
    border: 1px solid #591BC5;
}

.ql-align-center {
  text-align: center;
}

@media (max-width: 780px) {
  .card-project {
    width: auto;
  }

  .card-project img {
    width: 45%;
  }
}