#modal-add,
#modal-contact {
    display: none;
    position: fixed;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
}

.modal-wrapper-add,
.modal-wrapper-contact {
	overflow: auto;
	width: 630px;
	max-width: calc(100vw - 100px);
	max-height: calc(105vh - 100px);
	background-color: #ebe0ff;
	border-radius: 10px;
	display: flex;
	align-content: center;
	flex-direction: column;
	padding-bottom: 30px;
}

.modal-wrapper-contact {
	max-width: calc(100vw - 100px);
	max-height: calc(100vh - 141px);
	transform: translateY(7%);
}

.modal-nav {
	display: flex;
	justify-content: flex-end;
	margin: 32px 32px 0px 32px;
}

.modal-close-button {
font-size: 12px;
	width: fit-content;
  color: #591BC5;
  font-size: 17px;
  border:1px solid #EFEEF5;
  font-weight: 500;
  background: #EFEEF5;
  border-radius: 20px;
  padding: 3px 5px; 
  cursor:pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
}

.modal-close-button:hover {
	border-radius: 0;
}