.home {
    min-height: 100vh;
    height: fit-content;
    position: relative;
}

.home-content {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 90rem;
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-title {
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #FFF;
    font-weight: 800;
    text-align: center;
    margin: 15px;
}

.home-info {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    line-height: 1.6;
    color: #FFF;
}

@media (max-width: 780px) {
    .home-title {
        font-size: 1.7rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #FFF;
        font-weight: 800;
        text-align: center;
        margin: 15px;
    }

    .home-info {
        font-size: 1rem;
        text-align: center;
        width: 100%;
        line-height: 1.6;
        color: #FFF;
    }
}